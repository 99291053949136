.nav-brand-img {
  padding-left: 7px;
  padding-right: 7px;
}

.navbar {
  background-color: #0e294a !important;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

$navbar-height: 5.25rem;
$navbar-img-height: 4.25rem;
$navbar-padding-vertical: 1rem;
$navbar-padding-horizontal: 2rem;

$base-color: #c6538c;


.navbar-item img {
  max-height: 3.75rem;
}

.navbar-item {
  font-size: 1em;
}

// .navbar-item img {
//   max-height: $navbar-img-height;
// }

.navbar-brand {
  margin-left: 50px;
}

.button .button--rec {
  border-radius: 0 !important;
}

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 0rem;
}

@media screen and (min-width: 769px) {
  .navbar-item img {
    max-height: 1.75rem;
  }
}

@media screen and (min-width: 1406px) {

  .navbar-item,
  .navbar-link {
    padding: 0.5rem 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    min-height: $navbar-height;
  }

  .navbar-item img {
    max-height: 3.75rem;
  }
}

@media screen and (max-width: 1407px) {
  a.navbar-item:not(:first-child)::before {
    min-width: 1.25rem;
    width: 1.25rem;
  }

  .navbar-item {
    font-size: 0.75em;
  }

  .navbar-item .button {
    font-size: 1em;
  }
}

.navbar-brand.sticky {
  .navbar-item img {
    max-height: 1.75rem;
  }
}

.navbar-brand {
  margin-left: 25px;
}

@media screen and (min-width: 770px) {
  .ausyris-section {
    margin-top: -7.5rem;
  }
}

@media screen and (max-width: 769px) {
  .navbar.sticky {
    min-height: 0 !important;
  }

  .navbar {
    margin: 0 !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ausyris-section {
    margin-top: -10rem !important;
  }

  .section.auryris-padding {
    padding: 1.5rem 1.5rem;
  }

  .hero.hero-discuss-with-us,
  .content-mechanical-and-hydraulic {
    margin-top: auto !important;
  }

  .content.content-discuss-with-us {
    margin-left: auto !important;
  }

  .hero-discuss-with-us-content {
    // background-color: #0e294b !important;
    margin-top: -7.5rem;
  }

  .hero-electronics-and-electrotechnics-content,
  .hero-mechanical-and-hydraulic-content {
    // background-color: whitesmoke !important;
    margin-bottom: -7.5rem;
  }

  .hero-industrial-measurement-and-analysis-content,
  .hero-industrial-automation-and-it-content {
    // background-color: whitesmoke !important;
    margin-top: -7.5rem;
  }

  .content-mechanical-and-hydraulic,
  .mechanical-and-hydraulic-content,
  .content-electronics-and-electrotechnics {
    margin-right: auto !important;
  }

  .content-measurement-and-analysis,
  .content-industrial-automation-and-it {
    margin-left: auto !important;
  }
}


@media screen and (min-width: 1024px) {
  .ausyris-section {
    // margin-top: -11.5rem !important;
    margin-top: -7.5rem;
  }

}

.navbar-item {
  justify-content: center;
  min-width: 0;
}

div.navbar-item:first-child::before {
  content: " ";
  border: none;
  min-width: 1.5rem;
  width: 1.5rem;
  min-height: 2px;
  height: 2px;
  line-height: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .navbar-item {
    min-width: 200px !important;
  }
}
@media screen and (min-width: 1408px) {
  .navbar-item {
    min-width: 260px !important;
  }
}