@import "./../../assets/app-colors.scss";

$card-radius: 0;

.has-color-bleu-vert{
  color: $color-bleu-vert!important;
}

svg.has-color-bleu-vert, .has-color-bleu-vert{
  fill: $color-bleu-vert !important;
}
svg.has-color-bleu-vert:hover, .has-color-bleu-vert:hover{
  fill: $color-bleu-vert !important;
}

.card {
  background-color: #EAECEA !important;
  border-radius: $card-radius !important;
}

.card-header:last-child,
.card-content:last-child,
.card-footer:last-child {
  border-bottom-left-radius: $card-radius !important;
    border-bottom-right-radius: $card-radius !important;
}
