.content-measurement-and-analysis {
  padding: 3rem;
  margin-left: -10.5rem;
  z-index: 10;
}

.banner-measurement-and-analysis {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)),
    url(./../../../assets/mesures_analyses.webp);
  background-position: 50%;
  background-size: cover;
  transform: scaleX(-1);
}

// .column.col-1,
// .column.col-2 {
//   -ms-flex-preferred-size: 100%;
//   flex-basis: 100%;
// }

// // .hero-measurement-and-analysis{}
// @media (min-width: 768px) {

//   .column.col-1,
//   .column.col-2 {
//     -ms-flex-preferred-size: 48%;
//     flex-basis: 48%;
//   }
// }

// @media (max-width: 768px) {
//   .column.mobile-order-2 {
//     -webkit-box-ordinal-group: 3;
//     -ms-flex-order: 2;
//     order: 2;
//   }
// }