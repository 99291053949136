.content-industrial-automation-and-it{
  padding: 3rem;
  margin-left: -10.5rem;
  z-index: 10;
}

.banner-industrial-automation-and-it {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)),
    url(./../../../assets/industrial-automation-and-it.webp);
  background-position: 50%;
  background-size: cover;
  transform: scaleX(-1);
}

// .hero-industrial-automation-and-it{}