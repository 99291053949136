/* --colors */
$color-base: #0e294b;
$color-bleu-nuit: #0e294b;
$color-bleu-nuit-nacre: #0e294b;
/* $color-bleu-vert: #335B74; */
$color-bleu-vert: #1B2F3C;
$color-blanc-papyrus: #cbcfca;
$color-vert-mousse: #345247;
$color-jaune-citron: #dfb342;
$color-noir-fonce: #3c3c3c;
$border-dark: rgba($color-base, 0.88);

$turquoise: $color-bleu-nuit;
$primary: $color-bleu-nuit;
$blue: $color-bleu-nuit-nacre;
$link: $color-bleu-nuit-nacre;
$white: color-blanc-papyrus;

// :root {
//   --bulma-primary-h: 158deg;
//   --bulma-primary-s: 22%;
//   --bulma-primary-l: 26%;
//   --bulma-link-h: 213deg;
//   --bulma-link-s: 69%;
//   --bulma-link-l: 17%;
//   --bulma-info-h: 203deg;
//   --bulma-info-s: 39%;
//   --bulma-info-l: 33%;
//   --bulma-warning-h: 43deg;
//   --bulma-warning-s: 71%;
//   --bulma-warning-l: 57%;
//   --bulma-danger-s: 74%;
//   --bulma-danger-l: 59%;
// }

:root {
  --bulma-primary-h: 43deg;
  --bulma-primary-s: 71%;
  --bulma-primary-l: 57%;
  --bulma-link-h: 158deg;
  --bulma-link-s: 22%;
  --bulma-link-l: 26%;
  --bulma-info-h: 204deg;
  --bulma-info-s: 38%;
  --bulma-info-l: 69%;
  --bulma-success-h: 158deg;
  --bulma-success-s: 22%;
  --bulma-success-l: 54%;
  --bulma-warning-h: 43deg;
  --bulma-warning-s: 74%;
  --bulma-warning-l: 64%;
  --bulma-danger-h: 360deg;
  --bulma-danger-s: 75%;
  --bulma-danger-l: 71%;
}