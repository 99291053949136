.content.content-discuss-with-us {
  padding: 3em;
  margin-left: -16.5em;
  z-index: 10;
}

.hero.hero-discuss-with-us {
  margin-top: -7rem;
}


.banner-discuss-with-us {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)),
    url(./../../../assets/discuss_with_us.webp);
  background-position: 50%;
  background-size: cover;
  transform: scaleX(-1);
}