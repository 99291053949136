@import "./../../../assets/app-colors.scss";

.input {
  border-color: $color-jaune-citron !important;
  // background-color: $color-bleu-vert !important;
  // color: white !important;
  border-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.button.is-static {
  font-weight: bold;
  text-transform: uppercase;
  color: $color-jaune-citron !important;
  border-color: $color-jaune-citron !important;
  background-color: $color-bleu-vert !important;
  border-width: 2px;
}
.input::placeholder {
  // color: white;
}

.field.has-addons .control:first-child:not(:only-child) .button {
  border-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

}


.button.is-static.contact-us {
  min-width: 100px;
  justify-content: left;
}

@media screen and (min-width: 769px), print{
  .field-label {
    flex-grow: 0;
    margin-right: 0;
  }
}
@media screen and (min-width: 1024px), print{
  .field-label {
    flex-grow: 0;
    margin-right: 0;
  }
}

.checkbox:hover,
.radio:hover {
  color: $color-jaune-citron;
}

input[type="checkbox"]:hover {
  background-color: $color-jaune-citron;
}

.field.is-grouped.contact-us-grouped {
  justify-content: center;
}

.button,
.input,
.textarea,
.select select,
.file-cta,
.file-name,
.pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis{
  border-width: 2px;
}