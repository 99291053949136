@import "../../assets/app-colors.scss";

.tabs li.is-active a, .tabs.is-toggle li.is-active a{
  background-color: $color-bleu-nuit-nacre;
  border-color: $color-bleu-nuit-nacre;
}

.ausyris-section {
  margin-top: -7.5rem;
}

.content.ausyris-container {
  max-width: 756px;
  margin: 0 auto;
  position: relative;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 20px;
}

.button .is-success{
  color: $color-vert-mousse;
}

.button.is-success {
    background-color: $color-vert-mousse;
    border-color: transparent;
    color: #fff;
}
.button.is-success.is-outlined {
  background-color: transparent;
  border-color: $color-vert-mousse;
  color: $color-vert-mousse;
}
.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  background-color: $color-vert-mousse;
  border-color: $color-vert-mousse;
  color: #fff;
}

.button.is-success:hover, .button.is-success.is-hovered {
    background-color: $color-vert-mousse;
    border-color: transparent;
    color: #fff;
}

.tag:not(body).is-primary {
    background-color: $color-bleu-nuit-nacre;
    color: #fff;
}

.has-text-success {
  color: $color-vert-mousse;
}


@media screen and (min-width: 1408px) {
  .content.ausyris-container :not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 960px;
    margin: 0 auto;
    width: auto;
  }
    .section.ausyris-section, .ausyris-section {
    margin-top: -7.5rem !important;
  }
}
.button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
    background-color: $color-bleu-nuit-nacre;
    border-color: $color-bleu-nuit-nacre;
    color: #fff;
}

.button:focus, .button.is-focused {
    border-color: $color-bleu-nuit-nacre;
    color: $color-bleu-nuit-nacre;
}

.button:focus, .button.is-focused {
    border-color: $color-bleu-nuit-nacre;
    color: $color-bleu-nuit-nacre;
}

.button:hover, .button.is-hovered {
  /* border-color: $color-bleu-nuit-nacre;
  color: $color-bleu-nuit-nacre; */
  border-color: #b5b5b5;
  color: #363636;
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  
    border-color: $color-bleu-nuit-nacre;
    background-color: $color-bleu-nuit-nacre;
}

.button.is-primary:hover, .button.is-primary.is-hovered {
    background-color: $color-vert-mousse;
    border-color: $color-vert-mousse;
}




.button.is-success[disabled], fieldset[disabled] .button.is-success {
    background-color: $color-vert-mousse;
    border-color: $color-vert-mousse;
}