$base-color: #0e294b;
$bleu-nuit-color: #0e294b;
$jaune-citron-color: #dfb342;
$noir-fonce-color: #3c3c3c;
$border-dark: rgba($base-color, 0.88);

.alert {
  border: 1px solid $border-dark;
}
.myAnimation {
  animation-name: myAnimation;
  animation-duration: 5s;
}

.card-bg {
  background-color: $base-color;
}
.box, .card-bg-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

a.bleu-nuit-color,
.bleu-nuit-color {
  color: $bleu-nuit-color !important;
}

a.jaune-citron-color,
.jaune-citron-color {
  color: $jaune-citron-color !important;
}

a.noir-fonce-color,
.noir-fonce-color {
  color: $noir-fonce-color !important;
}

a.bg-bleu-nuit-color,
.button.bg-bleu-nuit-color,
.bg-bleu-nuit-color {
  // background-color: $bleu-nuit-color !important;
  background-color: #0e294b !important;
}
a.bg-jaune-citron-color,
.button.bg-jaune-citron-color,
.bg-jaune-citron-color {
  background-color: $jaune-citron-color !important;
}
a.bg-noir-fonce-color,
.button.bg-noir-fonce-color,
.bg-noir-fonce-color {
  background-color: $noir-fonce-color !important;
}
/* LOADER */
.card-animation {
  fill: none;
  animation: 1s ease-out 0s 1 slideInLeft;
  /* animation: rotation 2000ms ease-in-out alternate infinite; */
}

@keyframes rotation {
  0% {
    opacity: 50;
  }

  100% {
    opacity: 70;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.card-footer-item-vcard {
  background-color: #0e294b !important;
}